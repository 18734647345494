/**
Custom Hook useTargetRef for Adobe Target
*/
import React, { useRef, useEffect } from "react"
import document from "global/document";

function useTargetRef (location){
	const atRef = useRef(null);
    
	// simulate componentDidMount // console.log('in useAdobeTarget componentDidMount');
	useEffect(() => {
        console.log('in useAdobeTarget render once', location);
		
        // Use Prefetch Locations extension
        if(typeof "adobe" !== "undefined" && "target" in adobe && 
            typeof "adobe.target.ext" !== "undefined" && typeof adobe.target.ext.triggerLocations === "function"
        ){
            //TODO: move to reusable fn
            adobe.target.ext.triggerLocations([ location ]).then(
                function(offer) { 
                    console.log("AT: Prefetched Location", offer);
                    if (atRef && atRef.current && offer) {
                        atRef.current.innerHTML = offer;
                        console.log('did set ref', atRef.current.innerHTML);
                    }
                },
                function (error){ console.log("AT: Prefetched Location Error",error);}
            );
        }else{
            document.addEventListener("atx-prefetched-locations-loaded", (event) => {
                console.log("AT: useTargetRef: at-library-loaded", event);
                //TODO: move to reusable fn
                adobe.target.ext.triggerLocations([ location ]).then(
                    function(offer) { 
                        console.log("AT: Prefetched Location", offer);
                        if (atRef && atRef.current && offer) {
                            atRef.current.innerHTML = offer;
                            console.log('did set ref', atRef.current.innerHTML);
                        }
                    },
                    function (error){ console.log("AT: Prefetched Location Error",error);}
                );
            });
        }
        
        //setTimeout(() => { /**/ }, 5000);
    }, []);
	return atRef;
}

export default useTargetRef;