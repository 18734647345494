import React, { Component } from 'react'
import TargetContext from '../contexts/TargetContext';
// import { withRouter } from 'react-router-dom' //VU removed
import { notify, fireCustomEvent } from '../utils'
import document from "global/document";
import window from "global/window";
// import uuidv4 from "uuid/v4" //VU removed

class TargetProvider extends Component {
        
    
            // getReactComponent : (location, components, component) => {
            //     if(Array.isArray(this.state.mboxes)) { 
            //         const offer = adobe.consulting.target.prefetch_locations_response[location];
            //         console.log(offer);
            //         console.log("JJJJJJJJ");
            //         var offerResponse = {response:{
            //             prefetch : {
            //                 mboxes : [
            //                     offer
            //                 ]
            //             }
            //         }};
            //         //adobe.target.applyOffers(offerResponse);
            //         const notifications = TargetProvider.createNotification(offer, 'display', offer.options.map(e => e.eventToken))
            //         console.log(notifications);
            //         // adobe.target.sendNotifications({
            //         //     request: { notifications : [notifications]  }
            //         // });
            //     } // TODO prevent multiple send notifications
            //     return component;
            // }
    updateState(e)  {
        var data = e.detail;
        notify("Updating " + data.key + " state in Proxy event handler", data);
        var newState = {};
        this.setState(state => {
            newState[data.key] = data.source[data.key];
            return newState;
        });
    }
   
    constructor(props) {
        super(props);
        this.updateState = this.updateState.bind(this);
        document.addEventListener("adbe_c_t_p_r_received", this.updateState);

         // TODO Read Server Side Offers to the state
         // TODO test experience delivery on Home page
         // TODO test mbox response with JSON offers
         // TODO Dynamic Profile update and re-fetch offers and triggerview
         // TODO triggerView in launch not firing on view change
         // TODO JSON offers to components via triggerView
         this.state = {
            mboxes : {},
            views : {},
            location : "",
            triggerView : (view, options)  => {
                if(typeof adobe != "undefined" && typeof adobe.target != "undefined" && this.state.hasOwnProperty("views") && this.state.views.length > 0) {
                    notify("ReactJS re-rendering - Calling triggerView('" + view + "', { 'page' : false })");
                    adobe.target.triggerView(view, {"page": false}); //TODO Only trigger if current view has offers in the cache.
                } 
            },
            getReactComponent : (that, location, components, component) => { 
                const functionName = "target_loc_" + this.state.location + "_" + location;
                if(typeof window[functionName] === "function") {
                    return React.cloneElement(window[functionName].apply(that, components), {"className" : "target_loc_" + this.state.location + "_" + location});
                }
                else {
                    return React.cloneElement(component, {"className" : "target_loc_" + this.state.location + "_" + location});
                }
            }
        };
    }

    componentDidMount() {
        //VU error: react-dom.production.min.js:2883 Uncaught TypeError: Cannot read property 'pathname' of undefined
        //notify("Adobe Target Context Provider for " + ((this.state.location != "") ? ("View: '" + this.state.location + "'") : ("Location: '" + this.props.location.pathname + "'")) + " Mounted");
    }

    componentDidUpdate() {
        //VU error: react-dom.production.min.js:2883 Uncaught TypeError: Cannot read property 'pathname' of undefined
        //notify("Adobe Target Context Provider for " + ((this.state.location != "") ? ("View: '" + this.state.location + "'") : ("Location: '" + this.props.location.pathname + "'")) + " Updated");
    }

    static getDerivedStateFromProps(nextProps, prevState){
        //Test for no view configured and test for view configured and mboxTrace and raw mbox call
        if(typeof adobe != "undefined" && typeof adobe.consulting.target.views != "undefined") {
            var views = adobe.consulting.target.views[nextProps.location.pathname], nextLoc = "";
            for (let view of views) {
                if(view.notification === 1) { // TODO Document that there should only be one view per page with notification in it.
                    nextLoc = view.name;
                }
            }
            notify("Last view => " + (prevState.location == "" ? "Not available due to initial page load" : prevState.location));
            notify("Next view => " + nextLoc);
            if(prevState.location == "") {
                return { location: nextLoc };
            }
            else if(nextLoc !== prevState.location) {
                fireCustomEvent('view-change-begin', document.body, { "detail": { "views" : views } }); // TODO choose how to send the location.
                window.setTimeout(function() {
                    fireCustomEvent('view-change-end', document.body, { "detail": {} }); // Simulating View End
                }, 500)
                return { location: nextLoc };
            }
            return null;
        }
        else {
            return null;
        }
    }

    
    render() {
        return (
            <TargetContext.Provider value={ this.state } >
                { this.props.children }
            </TargetContext.Provider>
        );
    }
}

export default TargetProvider;
// export default withRouter(TargetProvider); //VU removed