/**
Custom Hook useTargetLocation for Adobe Target
*/
import React, { useState, useEffect, useCallback } from "react"
import document from "global/document";

//TODO use redux selector hook to load AT content from redux

//TODO: think how to avoid using dangerouslysethtml
// see https://stackoverflow.com/questions/36104302/how-do-i-convert-a-string-to-jsx 
// answer for <StringToJSX domString={domString}/>

function useTargetLocation (location){
    const [offer, setOffer] = useState(null);

    const setValidOffer = (location) => {
        adobe.target.ext.triggerLocations([ location ]).then(
            function(offer) { 
                console.log("TargetLocation Hook: Prefetched Location ext", offer);
                if (offer && offer.length > 0) 
                    setOffer(offer[0]);
            },
            function (error){ console.log("TargetLocation Hook: Prefetched Location Error",error);}
        );
    }
    // simulate componentDidMount // console.log('in useAdobeTarget componentDidMount');
	useEffect(() => {
        console.log('TargetLocation Hook: render once', location);
		
        // Use Prefetch Locations extension
        if(typeof "adobe" !== "undefined" && "target" in adobe && 
            typeof "adobe.target.ext" !== "undefined" && typeof adobe.target.ext.triggerLocations === "function"
        ){
            setValidOffer(location);
        }else{
            document.addEventListener("atx-prefetched-locations-loaded", (event) => {
                console.log("TargetLocation Hook: at-library-loaded event response", event);
                setValidOffer(location);
            });
        }
        
        //setTimeout(() => { /**/ }, 5000);
    }, []);
	return {
        offer, 
        applyOffer: useCallback(str => <div dangerouslySetInnerHTML={{__html: str}} /> )
    };
}

/*

    import React from "react";

    let getNodes = str =>
      new DOMParser().parseFromString(str, "text/html").body.childNodes;
    let createJSX = nodeArray => {
      const className = nodeArray[0].className;
      return nodeArray.map(node => {
        let attributeObj = {};
        const {
          attributes,
          localName,
          childNodes,
          nodeValue
        } = node;
        if (attributes) {
          Array.from(attributes).forEach(attribute => {
            if (attribute.name === "style") {
              let styleAttributes = attribute.nodeValue.split(";");
              let styleObj = {};
              styleAttributes.forEach(attribute => {
                let [key, value] = attribute.split(":");
                styleObj[key] = value;
              });
              attributeObj[attribute.name] = styleObj;
            } else {
              attributeObj[attribute.name] = attribute.nodeValue;
            }
          });
        }
        return localName ?
          React.createElement(
            localName,
            attributeObj,
            childNodes && Array.isArray(Array.from(childNodes)) ?
            createJSX(Array.from(childNodes)) :
            []
          ) :
          nodeValue;
      });
    };

    export const StringToJSX = props => {
      return createJSX(Array.from(getNodes(props.domString)));
    };

*/

export default useTargetLocation;