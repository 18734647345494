import React, { useState, useEffect } from "react";
import document from "global/document";

const AdobeTarget = props => {
    const location = props.location;
    const children = props.children;
    const [offer, setOffer] = useState(null);
    const [isHidden, setIsHidden] = useState(true);
    const hiddenStyle = { visibility: "hidden", opacity: 0 };
    const unhideContent = () => {
      setIsHidden(false);
      setOffer(null);
    }
    const setValidOffer = (location) => {
      if(typeof window.adobe !== "undefined"){  
        window.adobe.target.ext.triggerLocations([ location ]).then(
            function(offer) { 
                console.log("AdobeTarget Component: Prefetched Location ext", offer);
                if (offer && offer.length > 0) 
                    setOffer(offer[0]);
            },
            function (error){ 
              console.log("AdobeTarget Component: Prefetched Location Error",error);
              unhideContent();
            }
        );
      }else{
        unhideContent();
      }
    }
    useEffect(() => {
        console.log('AdobeTarget Component: render once', location);
		
        // Use Prefetch Locations extension
        if(typeof window.adobe !== "undefined" && "target" in window.adobe && 
            typeof "adobe.target.ext" !== "undefined" && typeof window.adobe.target.ext.triggerLocations === "function"
        ){
            setValidOffer(location);
        }else{
            document.addEventListener("atx-prefetched-locations-loaded", (event) => {
                console.log("AdobeTarget Component: at-library-loaded event response", event);
                setValidOffer(location);
            });
        }
        
    }, []);
    
    console.log('AdobeTarget Component: offer', offer);
    if (offer){
        console.log('AdobeTarget Component: in render() with offer');  
        return (
            <div dangerouslySetInnerHTML={{__html: offer}} />
            // extend with <StringToJSX domString={offer}/>
        );
    }else if (isHidden == true) {
        console.log('AdobeTarget Component: in render() with hidden style');  
        return <div style={hiddenStyle}>{children}</div>;
    }else {
        console.log('AdobeTarget Component: in render() without hidden style'); 
        return <div>{children}</div>;
    }
    
}

export default AdobeTarget


//TODO: https://stackoverflow.com/questions/36104302/how-do-i-convert-a-string-to-jsx
let getNodes = str =>
  new DOMParser().parseFromString(str, "text/html").body.childNodes;
let createJSX = nodeArray => {
  const className = nodeArray[0].className;
  return nodeArray.map(node => {
    let attributeObj = {};
    const {
      attributes,
      localName,
      childNodes,
      nodeValue
    } = node;
    if (attributes) {
      Array.from(attributes).forEach(attribute => {
        if (attribute.name === "style") {
          let styleAttributes = attribute.nodeValue.split(";");
          let styleObj = {};
          styleAttributes.forEach(attribute => {
            let [key, value] = attribute.split(":");
            styleObj[key] = value;
          });
          attributeObj[attribute.name] = styleObj;
        } else {
          attributeObj[attribute.name] = attribute.nodeValue;
        }
      });
    }
    return localName ?
      React.createElement(
        localName,
        attributeObj,
        childNodes && Array.isArray(Array.from(childNodes)) ?
        createJSX(Array.from(childNodes)) :
        []
      ) :
      nodeValue;
  });
};

export const StringToJSX = props => {
  return createJSX(Array.from(getNodes(props.domString)));
};