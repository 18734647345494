import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { TargetConsumer } from '../../contexts/TargetContext';
import useTargetRef from '../../hooks/TargetRef';
import AdobeTarget from '../adobe-target';
import CustomTag from '../customtag.js';//test CustomTag

// STEP 1: add TargetLocation Hook
import useTargetLocation from '../../hooks/TargetLocation';
////



const applyTargetOfferOnComponentUpdate = function(){
	$("#content > section:nth-child(7) > div > div:nth-child(4)").html(
		'<div class="row"><div class="col-lg-4"><div class="testimonial-item mx-auto mb-5 mb-lg-0"><img class="img-fluid rounded-circle mb-3" src="/static/testimonials-1-828e0935e45611b23593d139bd1984f1.jpg" alt=""><h5>Margaret E.</h5><p class="font-weight-light mb-0">"<!-- -->Adobe Consulting is fantastic! Thanks so much guys!<!-- -->"</p></div></div><div class="col-lg-4"><div class="testimonial-item mx-auto mb-5 mb-lg-0"><img class="img-fluid rounded-circle mb-3" src="/static/testimonials-2-f3c0271e2279f2bdeea02ddc3aa03dc5.jpg" alt=""><h5>Fred S.</h5><p class="font-weight-light mb-0">"<!-- -->Adobe products are amazing. I\'ve been using it to create lots of personalized content.<!-- -->"</p></div></div><div class="col-lg-4"><div class="testimonial-item mx-auto mb-5 mb-lg-0"><img class="img-fluid rounded-circle mb-3" src="/static/testimonials-3-15393c5a215a67b0f4bbc4d4ed55f12c.jpg" alt=""><h5>Sarah W.</h5><p class="font-weight-light mb-0">"<!-- -->Thanks so much for making Adobe solutions available to us!<!-- -->"</p></div></div></div>'
	);
}


const RerenderingExampleContent = (props) => {
	
	// STEP 2: pull Target offer based on mbox
	const adobeTarget = (props.type == "Redux Prefetch") ? useTargetLocation("location-testimonial-2") : null;
	////

	console.log("AT: useTargetLocation: Received adobeTarget obj", adobeTarget, typeof adobeTarget);

	// Testimonials data
	const img1 = require('../../../static/img/testimonials-1.jpg');
	const img2 = require('../../../static/img/testimonials-2.jpg');
	const img3 = require('../../../static/img/testimonials-3.jpg');
	let origTestimonials = [
		{img: img1, name: "Margaret E.", message: "Adobe Consulting is fantastic! Thanks so much guys!" },
		{img: img2, name: "Fred S.", message: "Adobe products are amazing. I've been using it to create lots of personalized content." },
		{img: img3, name: "Sarah W.", message: "Thanks so much for making Adobe solutions available to us!" }
	];

	// randomizing testimonials
	const shuffle = function(array) {
		let currentIndex = array.length, temporaryValue, randomIndex;
		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		return array;
	}
	const [testimonials, setTestimonials] = useState(origTestimonials);
    const rerenderComponent = e => {
		setTestimonials(shuffle([...testimonials])); // clone and shuffle array
		console.log(testimonials[0].name,testimonials[1].name,testimonials[2].name);
    };

	// simulate componentDidMount
	useEffect(() => {
        console.log('in componentDidMount');
		//applyTargetOfferByRef(atRef);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		console.log('in componentDidMount + componentDidUpdate');
		applyTargetOfferOnComponentUpdate();
	});

	return <section className="testimonials text-center bg-light">
	  <div className="container">
	    <h2 className="mb-5">Testimonials</h2>
		<h3 className="mb-5">Re-Rendering Example: {props.type}</h3>
		<div className="row">
			<button className="btn btn-block btn-lg btn-primary" onClick={rerenderComponent} >Cause Re-render to Shuffle Testimonials</button>
			<h4>Random Number : { Math.random() }</h4>
		</div>
	    <div className="row">
			{props.type == "Default" && testimonials.map((testimonial, index) => (
				<div className="col-lg-4">
					<div className="testimonial-item mx-auto mb-5 mb-lg-0">
						<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
						<h5>{testimonial.name}</h5>
						<p className="font-weight-light mb-0">"{testimonial.message}"</p>
					</div>
				</div>
			))}
			{props.type == "Redux Prefetch" && testimonials.map((testimonial, index) => (
				
					<div className="col-lg-4">
						{
						index == 2 && 

						// STEP 3: display offer if available
						adobeTarget.offer ? 
						adobeTarget.applyOffer(adobeTarget.offer)
						: (
							<div className="testimonial-item mx-auto mb-5 mb-lg-0">
								<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
								<h5>{testimonial.name}</h5>
								<p className="font-weight-light mb-0">"{testimonial.message}"</p>
							</div>
						)
						////
						}
					</div>
				
			))}
			{props.type == "Target Wrapper" && testimonials.map((testimonial, index) => (
				
				<div className="col-lg-4">
					{
						index == 2 ? 
						(
							<AdobeTarget location="location-testimonial-2">
								<div className="testimonial-item mx-auto mb-5 mb-lg-0">
									<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
									<h5>{testimonial.name}</h5>
									<p className="font-weight-light mb-0">"{testimonial.message}"</p>
									<CustomTag></CustomTag>
								</div>
							</AdobeTarget>
						)
						: (
							<div className="testimonial-item mx-auto mb-5 mb-lg-0">
								<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
								<h5>{testimonial.name}</h5>
								<p className="font-weight-light mb-0">"{testimonial.message}"</p>
							</div>
						)
						////
					}
				</div>
			))}
			{props.type == "Ref Hook" && testimonials.map((testimonial, index) => (
				
				<div ref={index == 2 ? useTargetRef("location-testimonial") : null} className="col-lg-4">
					<div className="testimonial-item mx-auto mb-5 mb-lg-0">
						<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
						<h5>{testimonial.name}</h5>
						<p className="font-weight-light mb-0">"{testimonial.message}"</p>
					</div>
				</div>
			))}
			{props.type == "Component Did Mount and Did Update" && testimonials.map((testimonial, index) => (
				
				<div className="col-lg-4">
					<div className="testimonial-item mx-auto mb-5 mb-lg-0">
						<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
						<h5>{testimonial.name}</h5>
						<p className="font-weight-light mb-0">"{testimonial.message}"</p>
					</div>
				</div>
			))}
			{props.type == "TargetContext" && testimonials.map((testimonial, index) => (
				<TargetConsumer>
				{(context) => {
					context.triggerView(context.location); // TODO idea of DOM Location to detect sub views
					return (
						<div className="col-lg-4">
							<div className="testimonial-item mx-auto mb-5 mb-lg-0">
								<img className="img-fluid rounded-circle mb-3" src={testimonial.img} alt="" />
								<h5>{testimonial.name}</h5>
								<p className="font-weight-light mb-0">"{testimonial.message}"</p>
							</div>
						</div>
					)
				}}
				</TargetConsumer>
			))}
	    </div>
	  </div>
	</section>

}

export default RerenderingExampleContent
